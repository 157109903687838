@import "/src/assets/fonts/avenir-next-webfontkit/stylesheet.css";

/*
avenir_nextheavy, avenir_nextheavy_italic
avenir_nextbold, avenir_nextbold_italic
avenir_nextdemi_bold, avenir_nextdemi_bold_italic
avenir_nextmedium, avenir_nextmedium_italic
avenir_nextregular, avenir_nextitalic
avenir_nextultra_light, avenir_nextultra_light_italic
*/

/* Base Styles */
* {
  font-family: "avenir_nextregular", Arial, sans-serif;
}

::selection {
  background: #ffc1b2;
  color: #000;
  /* ::selection -> WebKit/Blink Browsers */
}

::-moz-selection {
  background: #ffc1b2;
  color: #000;
  /* ::-moz-selection -> Gecko Browsers */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  /* max-width: 600px; */
  margin: 0 auto;
  border-bottom: 1px solid #c8c8c8;
}

.navbar h1 {
  color: #fc6;
  /* font-family: "avenirheavy", Arial, sans-serif; */
}

.navbar .links {
  margin-left: auto;
}

.navbar a {
  color: white;
  margin-left: 16px;
  /* font-family: "avenirmedium"; */
  text-decoration: none;
  padding: 6px;
}

.navbar a:hover {
  color: #fc6;
}

.custom-button {
  text-transform: none;
}

.logo-expanded-light {
  background: url("./assets/images/logo/fullLogoBlack512.png") no-repeat;
  width: 240px;
}

.logo-expanded-dark {
  background: url("./assets/images/logo/fullLogoColor512.png") no-repeat;
  width: 240px;
}

.logo-compressed-light {
  background: url("./assets/images/logo/boltLogoBlack512.png") no-repeat;
  width: 30px;
}

.logo-compressed-dark {
  background: url("./assets/images/logo/boltLogoColor512.png") no-repeat;
  width: 30px;
}

.logo-compressed-light,
.logo-compressed-dark,
.logo-expanded-light,
.logo-expanded-dark {
  height: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-size: contain;
  background-position: center;
  display: flex;
}

.toolbar-button-light {
  border-bottom: 2px solid #00000000;
}

.toolbar-button-light:hover {
  border-bottom: 2px solid #00000080;
}

.toolbar-button-light-active {
  border-bottom: 2px solid #000;
}

.toolbar-button-dark {
  border-bottom: 2px solid #00000000;
}

.toolbar-button-dark:hover {
  border-bottom: 2px solid #ffffff80;
}

.toolbar-button-dark-active {
  border-bottom: 2px solid #fff;
}

.toolbar-button {
  border-bottom: 2px solid #ffc1b200;
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  /* delete for transparent background */
  margin-bottom: 30px;
}

.placeholder-light {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23FFEBD2FF' stroke-width='7' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  padding: 2em 0px;
}

.placeholder-dark {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%232A2A2AFF' stroke-width='7' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  /* margin: 1em 0px; */
  padding: 2em 0px;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-col-space {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-row-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-row-even {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.margin-auto-container {
  width: auto;
  display: flex;
  background-color: yellow;
}

.child-container {
  /* width: auto; */
  /* height: auto; */
  /* margin: 0 auto; */
  background-color: red;
}

.margin-auto-item {
  margin: 0 auto;
  background-color: green;
}

#form-file-upload {
  background-color: aqua;
}

.drag-active {
  background-color: #ffffff;
}

.click-here-text {
  cursor: pointer;
}

.click-here-text:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.stickyHeader {
  position: sticky;
  top: 75px;
  z-index: 1;
  transition: all 0.125s ease-in-out;
}

.stickyHeader.enabled-light,
.stickyHeader.enabled-dark {
  padding: 10px 20px;
  margin: 0 15px;
  border-radius: 150px;
}

.stickyHeader.enabled-light {
  background-color: #fff;
  border: 2px solid #121212;
  box-shadow: 3px 3px #121212;
}

.stickyHeader.enabled-dark {
  background-color: #121212;
  border: 2px solid #121212;
  box-shadow: 3px 3px #ffffff80;
}

.sentinel {
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.icon-button {
  opacity: 0;
}

.hover-container:hover > * .icon-button {
  opacity: 1;
}

:root {
  --blue: #7638fa;
  --purple: #d300c5;
  --red: #ff0069;
  --orange: #ff7a00;
  --yellow: #ffd600;
}

.instagram {
  background: radial-gradient(
      circle farthest-corner at 28% 100%,
      var(--yellow) 0%,
      var(--orange) 22%,
      var(--red) 35%,
      transparent 65%
    ),
    linear-gradient(145deg, var(--blue) 10%, var(--purple) 70%);
}
