/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 29, 2023 */



@font-face {
    font-family: 'avenir_nextbold';
    src: url('avenirnext-bold-01-webfont.woff2') format('woff2'),
         url('avenirnext-bold-01-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextbold_italic';
    src: url('avenirnext-bolditalic-02-webfont.woff2') format('woff2'),
         url('avenirnext-bolditalic-02-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextdemi_bold';
    src: url('avenirnext-demibold-03-webfont.woff2') format('woff2'),
         url('avenirnext-demibold-03-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextdemi_bold_italic';
    src: url('avenirnext-demibolditalic-04-webfont.woff2') format('woff2'),
         url('avenirnext-demibolditalic-04-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextheavy';
    src: url('avenirnext-heavy-09-webfont.woff2') format('woff2'),
         url('avenirnext-heavy-09-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextheavy_italic';
    src: url('avenirnext-heavyitalic-10-webfont.woff2') format('woff2'),
         url('avenirnext-heavyitalic-10-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextitalic';
    src: url('avenirnext-italic-05-webfont.woff2') format('woff2'),
         url('avenirnext-italic-05-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextmedium';
    src: url('avenirnext-medium-06-webfont.woff2') format('woff2'),
         url('avenirnext-medium-06-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextmedium_italic';
    src: url('avenirnext-mediumitalic-07-webfont.woff2') format('woff2'),
         url('avenirnext-mediumitalic-07-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextregular';
    src: url('avenirnext-regular-08-webfont.woff2') format('woff2'),
         url('avenirnext-regular-08-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextultra_light';
    src: url('avenirnext-ultralight-11-webfont.woff2') format('woff2'),
         url('avenirnext-ultralight-11-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'avenir_nextultra_light_italic';
    src: url('avenirnext-ultralightitalic-12-webfont.woff2') format('woff2'),
         url('avenirnext-ultralightitalic-12-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}